import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse }                             from '../base.model'
import { ImageModel }                                                      from '../image.model'
import { FormModel, FormStatusEnum, InitFormModel, InitFormStatusHistory } from './form.model'

export type FormCRModel = {
	baseForm: FormModel

	// a
	crNo?: string
	dateOfCompleteion?: Date
	applySbv?: boolean
	crImages?: ImageModel[]
	contractorRemarks?: string

	// b
	iowAgreementOnCompletion?: boolean
	reasonOfIncompletion?: string
	outstandingWorks?: boolean
	outstandingDetails?: string
	ectoAgreementOnCompletion?: boolean

	// c
	crOutstandingWorks?: OutstandingWorks[]

	signatureRequired: boolean
	signatureBase64?: string
	notifyUserList?: string[]
	submissionComment?: string

	firstSubmittedCompletionDate?: Date
}

export interface OutstandingWorks {
	id: number
	status: boolean
	submissionNo?: number
	dateOfOutstandingWorksCompleteion?: Date
	crOutstandingWorkImages?: ImageModel[]
	iowRemarks?: string
}

export interface CrListResponse extends BaseListResponse {
	list: FormCrListModel[]
}

export interface CrFilterOptions extends BaseFilterOptions {
	workOrderIdList: KeyValPair[]
}

export const InitCRForm: FormCRModel = {
	signatureRequired: false,
	baseForm         : {
		...InitFormModel,
		formStatus         : FormStatusEnum.FORM_CR_DRAFT,
		formStatusName     : 'Draft',
		formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }]
	}
}

export interface FormCrListModel {
	formId: number
	color: string
	formStatus: string
	formStatusShortName: string
	formStatusColor: string

	reportNo: string
	workOrderNo?: string
	completionDate?: Date

	submittedBy: string
	approvedBy: string
	updatedAt: Date
}

export interface CRGeneralOptions {
}

export interface CompletionReportAverageProcessingTime {
	month: string
	days: number
}

export interface CompletionReportOutstandingWork {
	month: string
	isOutstandingWork: 'Y' | 'N'
	total: number
}

export interface CrDashboard {
	outstandingWork: Array<CompletionReportOutstandingWork>
	averageProcessingTime: Array<CompletionReportAverageProcessingTime>
}

export interface CrDashboardReq {
	districtId: Array<number>
	startDate: Date,
	endDate: Date,
}