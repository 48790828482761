import { Fragment, useContext, useReducer, useRef, useState }                     from 'react'
import { NavigateFunction, useNavigate }                                          from 'react-router-dom'
import SearchPanel                                                                from '@components/searchPanel'
import { Button, Grid, IconButton, Typography, useMediaQuery, useTheme, Tooltip } from '@mui/material'
import * as common                                                                from '@common/common'
import { GlobalContext }                                                          from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext }                            from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState }                                   from '@reducers/searchPanelReducer'
import moment                                                                     from 'moment'
import useTableView                                                               from '@hooks/useTableView'
import { useTranslation }                                                         from 'react-i18next'
import FormStatusLabel                                                            from '@components/FormStatusLabel'
import GetValueWithKey                                                            from '@utils/getValueWithKey'
import { FormSdListModel, InitSDForm, SdFilterOptions }                           from '@services/model/form/form.SD.model'
import FormSDService                                                              from '@services/formService/form.SD.service'
import { NavigateTo }                                                             from '@utils/navigate'
import { FormStatusEnum, RightsCategory }                                         from '@services/model/form/form.model'
import svgUrl                                                                     from '@images/file-copy-line.svg'
import { Box, Stack }                                                             from '@mui/system'
import { DialogContextProps }                                                     from '@components/form/switcher'
import FormDialog                                                                 from '@components/form/dialog_v2'
import FormSelect                                                                 from '@components/form/select'
import FormController                                                             from '@components/form/controller'
import { useForm }                                                                from 'react-hook-form'
import FormDateTimePicker                                                         from '@components/form/dateTimePicker'

const DialogFormField = (props: { children: any; fieldName: string }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        gap: 1,
        ...(matchDownMd && {
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
      }}>
      <Box
        {...(!matchDownMd && {
          sx: {
            width: 200,
          },
        })}>
        <Typography>{t(props.fieldName)}:</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
        }}>
        {props.children}
      </Box>
    </Box>
  )
}

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const theme = useTheme()
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<SdFilterOptions>({
                                                                          submittedByList: [],
                                                                          approvedByList : [],
                                                                          formIdList     : [],
                                                                          workOrderIdList: []
                                                                      })

  const getExtraListInfo = async () => {
    let extraInfo = await FormSDService.GetSdFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<FormSdListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'workOrderId',
        desc: t('Work Order No', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => <Fragment>{record.workOrderNo}</Fragment>,
      },
      {
        key: 'location',
        desc: t('Location', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => <Fragment>{record.location}</Fragment>,
      },
      {
        key: 'formDate',
        desc: t('Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => (
          <Fragment>
            {record.formDate ? moment(record.formDate).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormSdListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],

    onRowClick: function (navigate: NavigateFunction, record: FormSdListModel) {
      NavigateTo(navigate, '/site-diary/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('SD', JSON.stringify(state.filterResult))
        let resp = await FormSDService.GetSdList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  const [dialogContext, setDialogContext] = useState<DialogContextProps>({
    title: <></>,
  })

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const handleDialogOpen = () => {
    setDialogOpen(true)
  }
  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const defaultValues: { formId: string | null, reportDate: Date } = { formId: null, reportDate: new Date() }

  const methods = useForm({ defaultValues })

  const { control, getValues } = methods

  const navigate = useNavigate()

  const onSubmit = async() => {
      const form                   = getValues()
      const { formId, reportDate } = form

      if (formId) {
          const data = await FormSDService.GetExistingSD(formId, reportDate?.toISOString())
          if (data) {
              handleDialogClose()
              methods.reset()
              data.baseForm.formStatus          = FormStatusEnum.FORM_SD_DRAFT
              data.baseForm.formStatusName      = 'Draft';
              data.baseForm.formStatusHistories = InitSDForm.baseForm.formStatusHistories
              navigate('/site-diary', { state: { cloneData: data } })
          }
      }
  }
  const onAddExisting = async () => {
      let resp = await FormSDService.GetSdList(
        {
          pagination: {
            skipPagination: true
          },
          formStatus: {
            operator: '!=',
            value: [globalState.formStatusList?.filter((status) => { 
              return status.actionCode === FormStatusEnum.FORM_SD_DRAFT
            })[0].id]
          }
        },
        null,
        false
      )
      const options = (resp?.list || []).map(({ formId, reportNo }) => ({ label: reportNo, value: formId }))

    setDialogContext({
      title: (
        <Typography variant="body1">
          {t('Copy Data to new Site Diary')}
        </Typography>
      ),
      children: (
        <Box>
          <DialogFormField fieldName='Site Diary Record'>
            <FormController
              controllerProps={{
                name: 'formId',
                control: control,
              }}
            >
              <FormSelect
                fullWidth
                options={options?.map((x) => {
                  return {
                    key: x.value,
                    value: x.label
                  }
                })}
              />
            </FormController>
          </DialogFormField>
          <DialogFormField fieldName='Target Date'>
            <FormController
              controllerProps={{
                name: 'reportDate',
                control: control,
              }}
            >
              <FormDateTimePicker uneditable={false}/>
            </FormController>
          </DialogFormField>
        </Box>
        ),
      buttons: (
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onSubmit}>
            {t('Confirm')}
          </Button>
          <Button variant="outlined" onClick={handleDialogClose}>
            {t('Cancel')}
          </Button>
        </Stack>
      ),
    })
    handleDialogOpen()
	}

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_SD))
                  ?.map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.formId?.value
                  : [],
            },
            {
              key: 'workOrderId',
              desc: 'Works Order No',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.workOrderIdList,
              defaultValue:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.workOrderId?.value
                  : [],
            },
            {
              key: 'location',
              desc: 'Location',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.location?.value
                  : '',
            },
            {
              key: 'formDate',
              desc: 'Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.formDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.formDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.formDate?.value?.max
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.SD !== 'undefined' && sessionStorage?.SD !== undefined
                  ? JSON.parse(sessionStorage?.SD)?.updatedAt?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <Tooltip title="Copy">
              <IconButton
                sx={{
                  backgroundColor: globalState.headerColor,
                  borderRadius: '10%',
                  width: '40px',
                  height: '40px',
                  '&:hover': { background: globalState.headerColor },
                }}
                onClick={onAddExisting}
                >
                <Button variant="text" >
                  <Box style={{ height: 20, width: 20 }}>
                      <img src={svgUrl} alt="road-icon" style={{ height: 'inherit', width: 'inherit', color: '#ffffff' }} />
                  </Box>
                </Button>
              </IconButton>
            </Tooltip>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
      <FormDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
    </Grid>
  )
}

export default AllRecord
