import { FormModel, InitFormModel, FormStatusEnum, InitFormStatusHistory, DashboardFormStatus } from './form.model'
import { FormNFModel }                                                                          from './form.NF.model'
import { ImageModel }                                                                           from '../image.model'
import { LocationModel }                                                                        from '../location.model'
import { BaseFilterOptions, BaseListResponse }                                                  from '../base.model'
import { KeyValPair }                                                                           from '@models/common'

export interface FormEIModel {
	// a
	baseForm: FormModel //
	from?: string // TODO remove later
	client?: string
	contractor?: string
	eiNo?: string
	location?: LocationModel
	roadName?: string
	locationRemark?: string
	dateOfCheck?: Date
	eiStatus?: boolean
	caseSource?: string
	caseSourceId?: number
	iccNo?: string
	emergencySerialNo?: string
	remarks?: string
	refNo?: string

	// b
	otherInfo?: string
	siteImages?: ImageModel[]
	formNf: FormNFModel[] //

	signatureRequired: boolean
	signatureBase64?: string
	notifyUserList?: string[]
	submissionComment?: string

	locationMapBase64?: string
	eiaNo?: string
}

export const InitEIForm: FormEIModel = {
	siteImages       : [],
	formNf           : [],
	baseForm         : {
		...InitFormModel,
		formStatus         : FormStatusEnum.FORM_EI_DRAFT,
		formStatusName     : 'Draft',
		formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }]
	},
	signatureRequired: true,
	dateOfCheck      : new Date()
}

export interface FormEIListModel {
	formId: number
	color: string
	formStatus: string
	formStatusShortName: string
	formStatusColor: string
	reportNo: string
	parentReportNo?: string
	contractNoId: number
	teamId: number[]
	districtId: number[]
	roadName: string
	inspectionDate: Date
	refNo: string

	submittedBy: string
	approvedBy: string
	updatedAt: Date
}

export interface EiDashboardCaseSource {
	caseSource: string
	total: number
}

export interface EiDashboard {
	formStatuses: Array<DashboardFormStatus>
	caseSources: Array<EiDashboardCaseSource>
}

export type EiGeneralOptions = {
	caseSource: {
		id: number
		name: string
	}[]
}

export type EIContractOptions = {
	caseSource: {
		id: number
		name: string
	}[]
}

export interface EiListResponse extends BaseListResponse {
	list: FormEIListModel[]
}

export interface EiFilterOptions extends BaseFilterOptions {
	parentFormIdList: KeyValPair[]
	workOrderIdList: KeyValPair[]
}

export const EiDashboardCategory: KeyValPair[] = [
	{ key: 'category', value: 'Category' },
	{ key: 'passingStatus', value: 'Passing Status' },
	{ key: 'worksOrders', value: 'Works Orders' }
]

export interface EiDashboardReq {
	teamId: Array<number>
	startDate: Date,
	endDate: Date
}