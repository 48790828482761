import {
  DashboardFormStatus,
  FormModel,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
} from './form.model'
import { LocationModel } from '../location.model'
import { ImageModel } from '../image.model'
import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'

export interface FormDNModel {
  baseForm: FormModel
  from?: string
  contractor?: string
  dnNo?: string
  sorItem?: string
  roadName?: string
  amountOfDeduction?: number
  location?: LocationModel
  reasonForAppeal?: string
  appealImages: ImageModel[]
  nfNo?: string
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string

  nonComplianceId?: number
  issuedAt?: Date
  startAt?: Date
  endAt?: Date
  approveState?: boolean
  resubmissionTime?: Date

  parentNf?: {
    from?: string
    contractNoId?: string
    nfNo?: string
    scopeOfWorksId?: number
    defectCategoryId?: number
    defectOptionId?: number
    rectificationAt?: Date
    timeLimitId?: number
    location?: LocationModel
    roadName?: string
    locationRemark?: string
    dateOfCheck?: Date
    dueDate?: Date
    remarks?: string
    eiNo?: string
  }

  parentDna?: {
    location?: LocationModel
    roadName?: string
    dnaNo?: string
    dateOfCheck?: Date
  }

  locationMapBase64?: string
  actualRefundAmount?: number
  referenceNo?: string

  dnCancelledBy?: string
  dateOfCancellation?: Date
  cancelComment?: string
}

export const InitDNForm: FormDNModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.FORM_DN_DRAFT,
    formStatusName: 'Draft',
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  appealImages: [],
}

export interface DnListResponse extends BaseListResponse {
  list: FormDnListModel[]
}

export interface DnFilterOptions extends BaseFilterOptions {
  parentFormIdList: KeyValPair[]
  workOrderIdList: KeyValPair[]
}

export interface FormDnIssueModel {
  baseForm: FormModel
  reasonForAppeal?: string
  appealImages: ImageModel[]
  signatureBase64?: string
}

export interface DnAppealPhotoImageModel {
  DnPhotoImageId: number
  uuid: string
  status: boolean
  description?: string
  filename: string
  size: number
  ext: string
  uuidRegular?: string
  uuidThumbnail?: string
  bytes: number[]
}

export interface FormDnListModel {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string

  from: string
  reportNo: string
  parentReportNo: string
  contractNoId: number
  teamId: number[]
  districtId: number[]
  roadName: string
  inspectionDate: Date
  issuedAt?: Date
  appealDueDate?: Date
  startAt: Date
  endAt: Date
  nonComplianceId: number
  workOrderId?: number
  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export interface DnDashboardTypeOfNonCompliance {
  typeOfNonCompliance: string
  total: number
}

export interface DnDashboard {
  formStatuses: Array<DashboardFormStatus>
  typesOfNonCompliance: Array<DnDashboardTypeOfNonCompliance>
}

export type TypoeOfNonCompliance = {
  id: number
  dnRefNo: string
  typeOfNonCompliance: string
  amountOfDeduction: number
  refundable: boolean
  refundAmount: number
  reoccurenceInHour?: number
  refundTimelimitInHour?: number
  refundDateType?: number
}

export type DNGeneralOptions = {
  typeOfNonComplianceList: TypoeOfNonCompliance[]
}

export interface DnDashboardReq {
  teamId: Array<number>
  startDate: Date
  endDate: Date
}
