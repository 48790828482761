import { KeyValPair }                          from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { ImageModel }                          from '../image.model'
import { LocationModel }                       from '../location.model'
import {
	BaseFormStatusDtoForList,
	DashboardFormStatus,
	FormModel,
	FormStatusEnum,
	InitFormModel,
	InitFormStatusHistory
}                                              from './form.model'
import { FormNeaModel, InitNEAForm }           from './form.NEA.model'
import { FormDeaModel }                        from './form.DEA.model'

export interface FormEaModel {
	baseForm: FormModel

	// a
	typeOfMmWorks?: number
	// districtId
	// workOrderId
	monthYearOfPerformanceStandard?: Date
	eaNotificationNoId?: number
	eaNoId?: number // db generated
	reportNo?: string
	contractorWitness?: string
	actualCheckingDate?: Date
	viableToConductInspection?: boolean
	auditLocationPlanImages?: ImageModel[]

	// part a dynamic components
	auditSequence?: number
	neaSampleNo?: number
	neaAuditCode?: string
	neaRoadName?: string
	neaShapeLength?: number
	neaRoadTypeId?: number
	neaRoadType?: string

	neaInspectionNo?: string
	neaStreet?: string
	neaShapeArea?: number
	neaLocation?: string
	neaStructureNo?: string

	neaPlanArea?: number
	neaGeoRegistrationNo?: string
	neaHydRegistrationNo?: string
	neaSectionCode?: string
	neaTunnel?: string

	neaLength?: number
	neaSlopeNo?: string
	neaVegetationId?: string
	// neaStructureTypeId?: number

	// b
	inspectionSection?: number
	totalInspectionSection?: number
	formDeaList: FormDeaModel[]

	// c
	eaImages?: ImageModel[]
	location?: LocationModel
	otherInfo?: string

	// d
	defective?: boolean | null
	defectiveList?: Array<EaDefective>
	issuedDate?: Date

	// e
	eaAppealImages?: ImageModel[]
	appealInfo?: string

	// f - signature
	signatureRequired: boolean
	signatureBase64?: string
	notifyUserList?: string[]
	submissionComment?: string

	formNea: FormNeaModel
	summaryMapBase64?: string
}

export const InitEAForm: FormEaModel = {
	formDeaList      : [],
	signatureRequired: true,
	baseForm         : {
		...InitFormModel,
		formStatus         : FormStatusEnum.FORM_EA_DRAFT,
		formStatusName     : 'Draft',
		formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }]
	},
	formNea          : InitNEAForm,
	defective        : false
}

export interface FormEaListModel extends BaseFormStatusDtoForList {
	formId: number
	color: string

	reportNo: string
	parentReportNo: string
	auditSequence: number
	typeOfMmWorks: number
	defective?: boolean
	monthYearOfPerformanceStandard: Date
	workOrderId?: number
	structureNo: string
	slopeNo: string
	location: string
	inspectionDate: Date

	submittedBy: string
	approvedBy: string
	updatedAt: Date
}

export interface EaListResponse extends BaseListResponse {
	list: FormEaListModel[]
}

export interface EaFilterOptions extends BaseFilterOptions {
	parentFormIdList: KeyValPair[]
	workOrderIdList: KeyValPair[]
	defective?: KeyValPair
	monthYearOfPerformanceStandard?: Date
	workOrderId: Array<KeyValPair>
	structureNo?: string
	slopeNo?: string
}

export interface EaCategory {
	typeOfMmWorks: number
	excelType: number
	sampleSize: number
	defectDefinition?: string
}

export interface EaDefective {
	sectionNo: number
	available: 'Y' | 'N'
	noOfDefectIdentified: number
	defective: 'Y' | 'N' | 'N/A'
}

export interface EaResultIssuingTime {
	date: string
	averageDays: number
}

export interface EaDashboardDefects {
	category: string
	defective: 'Y' | 'N'
	total: number
}

export interface EaDashboard {
	siteDefects: Array<EaDashboardDefects>
	resultIssuingTime: Array<EaResultIssuingTime>
}

export interface EaDashboardReq {
	districtId: Array<number>
	startDate: Date,
	endDate: Date
}